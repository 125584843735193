/*
 * Variaveis utilizadas no projeto.
 */

import addDays from './addDays';

const TIMES = {
  // Time constants
  FIVE_SECONDS: 5,
  ONE_DAY_SECONDS: 86400,
  ONE_MINUTE_SECONDS: 60,
  TEN_DAYS_SECONDS: 864000,
  SIX_MINUTES_SECONDS: 360,
  FIVE_MINUTES_SECONDS: 300,
  THREE_MINUTES_SECONDS: 180,
  ONE_YEAR_SECONDS: 31556952,
  ONE_MONTH_SECONDS: 2629800,
  TWO_MONTHS_SECONDS: 5259600,
  FIFTEEN_MINUTES_SECONDS: 900,
};

const STATUS_CODES = {
  REDIRECTS: [301, 302, 307, 308],
};

const VARIAVEIS_GLOBAIS = {
  // REVALIDATE TIME IN SECONDS,
  TIMES,
  // URL Global do Projeto
  HOST_GAMEARENA:
    process.env.isDev === 'true' && !process.env.simulateProd
      ? 'https://gameareana.com'
      : process.env.webHosting || 'https://gamearena.gg',
  DEFAULT_LOCAL_STORAGE: {
    menus: {
      purge: addDays({ date: new Date(), days: 2 }),
    },
  },
  PAGES_TYPES: {
    Page: 'Page',
    Tags: 'Tags',
    Article: 'Article',
    Authors: 'Authors',
    Category: 'Category',
    ArticleNews: 'ArticleNews',
    ArticleVideo: 'ArticleVideo',
  },
  // STATUS CODE
  STATUS_CODES,
  FORBIDDEN_ACCESS: 403,
  // POST TYPES
  POST_TYPES: {
    VIDEO: 'video',
  },
  // CACHE
  CACHE: {
    /** Cache duration for home page: 5 minutes for fresh content */
    HOME: TIMES.FIVE_MINUTES_SECONDS,
    /** Cache duration for category pages: 15 minutes balancing freshness and performance */
    CATEGORY: TIMES.FIFTEEN_MINUTES_SECONDS,
    /** Cache duration for category pages: 5 minutes balancing freshness and performance */
    CATEGORY_SMALL: TIMES.FIVE_MINUTES_SECONDS,
    /** Cache duration for latest news: 5 minutes for timely updates */
    SEARCH_RESULTS: TIMES.FIVE_MINUTES_SECONDS,
    /** Cache duration for articles: 2 months for static content */
    ARTICLES: TIMES.TWO_MONTHS_SECONDS,
    /** Cache duration for live matches: 1 minute for real-time updates */
    MATCH_PAGE_LIVE: TIMES.ONE_MINUTE_SECONDS,
    /** Cache duration for ended matches: 2 months as content is static */
    MATCH_PAGE_ENDED: TIMES.TWO_MONTHS_SECONDS,
    CATEGORIES_SMALL_CACHE: [
      '/esportes/',
      '/esportes/fatos-do-confronto/',
      '/esports/',
      '/esports/cs2/',
      '/esports/valorant/',
      '/games/',
    ],
  },
  SOCIAL_LINKS: [
    { name: 'Twitter', url: 'https://twitter.com/gamearenaofc' },
    { name: 'Youtube', url: 'https://www.youtube.com/@gamearenaofc' },
    { name: 'Instagram', url: 'https://www.instagram.com/gamearenaofc/' },
    { name: 'TikTok', url: 'https://www.tiktok.com/@gamearenaofc' },
    { name: 'Facebook', url: 'https://www.facebook.com/gamearenagg' },
    { name: 'Twitch', url: 'https://www.twitch.tv/gamearenaofc' },
    { name: 'Threads', url: 'https://www.threads.net/@gamearenaofc' },
    { name: 'Kwai', url: 'https://m.kwai.com/user/@gamearenaofc' },
  ],
  // REMOVE PUBLISHERS
  REMOVE_PUBLISHERS_POSTS_ID: [254691],
};

export default VARIAVEIS_GLOBAIS;
